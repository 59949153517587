import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Header.css';
import logo from '../../assets/logo.png';
import { Menu, X } from 'lucide-react';

const Header: React.FC = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsMenuOpen(false);
  }, [location.pathname]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className={`header ${isScrolled ? 'header--scrolled' : ''}`}>
      <div className="header__container">
        <div className="header__logo-container">
          <Link to="/">
            <img src={logo} alt="StyroR Logo" className="header__logo" />
          </Link>
        </div>
        <button className="header__menu-toggle" onClick={toggleMenu}>
          {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
        </button>
        <nav className={`header__nav ${isMenuOpen ? 'header__nav--open' : ''}`}>
          <ul className="header__nav-list">
            <li className="header__nav-item">
              <Link to="/" className="header__nav-link" onClick={() => setIsMenuOpen(false)}>Überblick</Link>
            </li>
            <li className="header__nav-item">
              <Link to="/modell" className="header__nav-link" onClick={() => setIsMenuOpen(false)}>Geschäftsmodell</Link>
            </li>
            <li className="header__nav-item">
              <Link to="/kontakt" className="header__nav-link" onClick={() => setIsMenuOpen(false)}>Kontakt</Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;