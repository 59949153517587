import React from 'react';
import HomeHero from './HomeHero';
import HomeFirst from './HomeFirst';
import HomeSecond from './HomeSecond';
import HomeThird from './HomeThird';

const Home: React.FC = () => {
  return (
    <div className="home">
      <HomeHero />
      <HomeFirst />
      <HomeSecond />
      <HomeThird />
    </div>
  );
};

export default Home;