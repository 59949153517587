import React from 'react';
import './Title.css';

interface TitleProps {
  animated?: string;
  static?: string;
}

const Title: React.FC<TitleProps> = ({ animated, static: staticText }) => {
  return (
    <h2 className="title">
      <span className="title-animated">{animated}</span>
      <span className="title-static">{staticText}</span>
    </h2>
  );
};

export default Title;