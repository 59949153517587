// Impressum.tsx
import React from 'react';
import './Impressum.css';

const Impressum: React.FC = () => {
  return (
    <main className="impressum">
      <div className="container">
        <h1 className="impressum__title">Impressum</h1>

        <section className="impressum__section">
          <h2>Unternehmensdetails</h2>
          <p>
            <strong>StyroR – Styropor Recycling Management und Beratung UG (haftungsbeschränkt) i.G.</strong><br />
            Vertretungsberechtigter Geschäftsführer: Andy Gailus<br />
            Am Pfeilshof 55<br />
            22393 Hamburg
          </p>
          <p>
            Telefon: <a href="tel:+4915903782712">+49 (0)1590 – 37 82 712</a><br />
            E-Mail: <a href="mailto:info@StyroR.de">info@StyroR.de</a><br />
            Internet: <a href="http://www.StyroR.de">www.StyroR.de</a>
          </p>
        </section>

        <section className="impressum__section">
          <h2>Standorte</h2>
          <p>Hamburg West: Am Barls 241, 22549 Hamburg</p>
          <p>Hamburg Ost: Am Pfeilshof 55, 22393 Hamburg</p>
        </section>

        <section className="impressum__section">
          <h2>Sitz der Gesellschaft</h2>
          <p>Hamburg</p>
        </section>

        <section className="impressum__section">
          <h2>Handelsregister</h2>
          <p>
            HRB i.G.<br />
            Amtsgericht Hamburg
          </p>
        </section>

        <section className="impressum__section">
          <h2>Redaktionell Verantwortlich</h2>
          <p>Andy Gailus</p>
        </section>

        <section className="impressum__section">
          <h2>Haftungsausschluss</h2>
          <p>
            Alle auf dieser Website enthaltenen Angaben und Informationen sind kostenlos und dienen der allgemeinen Information. Sie stellen unter keinen Umständen eine rechtliche oder andere Beratung dar. Wenn Sie eine Frage zu einem bestimmten Problem oder zu den Angaben auf dieser Website haben, wenden Sie sich bitte an uns. Wir bemühen uns, alle Inhalte dieser Website laufend zu aktualisieren. Gleichwohl können wir keine Garantie oder Haftung für die Vollständigkeit und jederzeitige inhaltliche Richtigkeit übernehmen. Sofern diese Website Links zu anderen Sites ermöglicht, handelt es sich um Verweise auf Inhalte anderer Betreiber, auf die der redaktionell Verantwortliche keinerlei Einfluss hat. Sie wurden lediglich bei der ersten Aufnahme auf rechtswidrige Inhalte überprüft. Eine Verantwortung bzw. Haftung hierfür kann in keinem Fall übernommen werden.
          </p>
        </section>

        <section className="impressum__section">
          <h2>Einverständniserklärung</h2>
          <p>
            Durch die Nutzung dieser Website erteilen Sie dem redaktionell Verantwortlichen die ausdrückliche Erlaubnis, Ihre personenbezogenen Daten zu erfassen und für statistische Zwecke, auch grenzüberschreitend, zu nutzen. Um die Navigation auf dieser Website zu erleichtern, können Cookies eingesetzt werden. Mit der Nutzung dieser Website erteilen Sie die ausdrückliche Erlaubnis, über die Cookies Ihr Nutzerverhalten für statistische Zwecke zu speichern und zu nutzen.
          </p>
        </section>

        <section className="impressum__section">
          <h2>Nutzungsbedingungen</h2>
          <p>
            Durch den Besuch dieser Internetseite entsteht kein Rechtsverhältnis. Der Inhalt dieser Seite stellt keine Beratung und kein Angebot auf Auftrag dar. Der Inhalt dieser Seite darf nur für persönliche Zwecke verwendet werden und unterliegt dem Verbot der Vervielfältigung. Die inhaltliche Verbreitung und Vervielfältigung bedarf der Zustimmung des Urhebers, dem redaktionell Verantwortlichen.
          </p>
        </section>
      </div>
    </main>
  );
};

export default Impressum;