import React, { useState } from 'react';
import { CheckCircle, Award, Recycle, Factory, Truck } from 'lucide-react';
import './HomeSecond.css';
import Title from '../../components/Title/Title';

const HomeSecond: React.FC = () => {
  const [activeFeature, setActiveFeature] = useState<number | null>(null);

  return (
    <section className="home-second-container">
      <div className="home-second-content">
        <Title
          animated="Umweltschutz,"
          static="... der Ihren Gewinn steigert!"
        />
        
        <div className="home-second-benefits">
          <div className="home-second-benefit">
            <div className="home-second-benefit-icon">
              <CheckCircle size={32} />
            </div>
            <p>Ihr Unternehmen spart teure Entsorgungskosten!</p>
          </div>
          <div className="home-second-benefit">
            <div className="home-second-benefit-icon">
              <CheckCircle size={32} />
            </div>
            <p>Gleichzeitig schützen Sie Natur und Umwelt!</p>
          </div>
        </div>

        <div className="home-second-certificate">
          <div className="home-second-certificate-icon">
            <Award size={64} />
          </div>
          <div className="home-second-certificate-content">
            <h3>Umweltschutz-Zertifikat</h3>
            <p>Zeigen Sie Ihren Kunden und der Welt, dass Sie und Ihr Unternehmen aktiv Umweltschutz betreiben!</p>
          </div>
        </div>

        <div className="home-second-features">
          <Title
            static="Unsere Merkmale"
          />
          <div className="home-second-features-grid">
            {[
              { icon: <Recycle size={48} />, title: "Zirkuläres Schaumstoff-Kreislauf-Management", description: "Geschlossener Styropor-Kreislauf für maximale Effizienz" },
              { icon: <Factory size={48} />, title: "Universelles Styropor-Airpop Recycling", description: "Sauber - Verdreckt - Verunreinigt" },
              { icon: <Truck size={48} />, title: "Zertifizierte Partner-Fachbetriebe", description: "Transport – Recycling – Maschinenbau – Produktion" }
            ].map((feature, index) => (
              <div 
                key={index}
                className={`home-second-feature ${activeFeature === index ? 'active' : ''}`}
                onMouseEnter={() => setActiveFeature(index)}
                onMouseLeave={() => setActiveFeature(null)}
              >
                <div className="home-second-feature-icon">{feature.icon}</div>
                <h4>{feature.title}</h4>
                <p>{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeSecond;