import React from 'react';
import BusinessModelFirst from './BusinessModelFirst';
import BusinessModelSecond from './BusinessModelSecond';

const BusinessModel: React.FC = () => {
  return (
    <div className="business-model">
      <BusinessModelFirst />
      <BusinessModelSecond />
    </div>
  );
};

export default BusinessModel;