import React, { useState } from 'react';
import './BusinessModelSecond.css';
import image2 from '../../assets/Bsp4.jpg';
import image3 from '../../assets/1.jpg';
import image4 from '../../assets/2.jpg';
import image5 from '../../assets/loop.jpg';
import Title from '../../components/Title/Title';

interface ModalProps {
  src: string;
  alt: string;
  onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({ src, alt, onClose }) => (
  <div className="business-model-second-modal" onClick={onClose}>
    <img src={src} alt={alt} className="business-model-second-modal-image" onClick={(e) => e.stopPropagation()} />
  </div>
);

const BusinessModelSecond: React.FC = () => {
  const [modalImage, setModalImage] = useState<string | null>(null);

  const openModal = (src: string) => setModalImage(src);
  const closeModal = () => setModalImage(null);

  const steps = [
    {
      title: "Volumenreduzierung",
      description: "Zunächst sorgen wir dafür, dass aus viel Styropor Volumen im ersten Schritt viel weniger wird.",
      image: image2
    },
    {
      title: "Komprimierung",
      description: "Anschließend wird das deutlich reduzierte Styropor-Volumen in Blöcken auf Paletten gestapelt.",
      image: image3
    },
    {
      title: "Transport",
      description: "Das jetzt im Volumen um ca. 80 Prozent reduzierte \"Styropor\" ist nun transportfertig und wird in die Recycling-Anlage verbracht.",
      image: image4
    },
    {
      title: "Neue Verwendung",
      description: "Anschließend kann es für diverse neue Produkte genutzt werden, z.B. Baustoffe. Oder es folgt die Re-Integration in den \"Styropor\"-Produktionskreislauf.",
      image: image5
    },
    {
      title: "Ergebnis",
      description: "Das spart große Mengen an Rohstoffen und CO2. Außerdem macht es unabhängig von politisch und sozial unfairen Ländern",
      image: null
    }
  ];

  return (
    <div className="business-model-second">
      <div className="business-model-second-content">
        <div className="business-model-second-title">
        <Title 
            static="Die Lösung für Ihr Abfallproblem"
          />
          <Title 
            animated="StyroR – StyroporRecycling"
          />
        </div>
        <p className="business-model-second-slogan">Verschmutzt - Belastet - Kontaminiert 🡢 Wir haben die Lösung für Sie!</p>
        <p className="business-model-second-description">Sparen Sie Kosten bei Entsorgung & Steigern Sie Ihren Gewinn</p>

        <div className="business-model-second-timeline">
          {steps.map((step, index) => (
            <div key={index} className="business-model-second-step">
              <div className="business-model-second-step-content">
                {step.image && (
                  <div className="business-model-second-step-image">
                    <img 
                      src={step.image} 
                      alt={`Step ${index + 1}: ${step.title}`} 
                      onClick={() => openModal(step.image!)}
                    />
                  </div>
                )}
                <div className={`business-model-second-step-text ${!step.image ? 'full-width' : ''}`}>
                  <h3 className="business-model-second-step-title">
                    <span className="business-model-second-step-number">{index + 1}</span>
                    {step.title}
                  </h3>
                  <p>{step.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {modalImage && (
        <Modal src={modalImage} alt="Expanded view" onClose={closeModal} />
      )}
    </div>
  );
};

export default BusinessModelSecond;