import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Package, Home, Utensils, Recycle, ChevronRight, Mail } from 'lucide-react';
import './HomeThird.css';
import Title from '../../components/Title/Title';

type ApplicationKey = 'electronics' | 'insulation' | 'food';

interface Application {
  icon: JSX.Element;
  title: string;
  items: string[];
}

const HomeThird: React.FC = () => {
  const [activeTab, setActiveTab] = useState<ApplicationKey>('electronics');

  const applications: Record<ApplicationKey, Application> = {
    electronics: {
      icon: <Package size={48} />,
      title: 'Transportverpackung für empfindliche Elektrogeräte',
      items: ['Handys', 'Kühlschränke', 'Fernseher'],
    },
    insulation: {
      icon: <Home size={48} />,
      title: 'Wärmedämmung',
      items: ['Fassaden', 'Böden', 'Decken'],
    },
    food: {
      icon: <Utensils size={48} />,
      title: 'Transportverpackung und Isolierung für Lebensmittel',
      items: ['speziell: Fisch & Fleisch','Speisen aller Art', 'speziell: Wein','Getränke aller Art', 'Lieferdienste'],
    },
  };

  return (
    <section className="home-third-container">
      <div className="home-third-content">
        <Title animated="Styropor® und AIRPOP®" static="Unverzichtbar im Alltag" />
        
        <div className="home-third-intro">
          <p>
            <strong>Styropor®</strong> ist der ehemalige Markenname und Gattungsbegriff für Schaumstoff, speziell für expandiertes (Poly)Styrol bzw. EPS.
            Seit einiger Zeit wird dieses Material unter dem neuen Begriff <strong>"AIRPOP®"</strong> geführt.
            Styropor Airpop ist aus dem modernen täglichen Leben nicht wegzudenken, es hat herausragend gute Eigenschaften. Es ist leicht, sicher, formbar, günstig, dämmt, isoliert und kann nun endlich auch vollständig recycelt werden. Die Anwendungen sind vielfältig und jeder nutzt es, z.B. als:
          </p>
        </div>

        <div className="home-third-applications">
          <div className="home-third-tabs">
            {(Object.entries(applications) as [ApplicationKey, Application][]).map(([key, { icon, title }]) => (
              <button
                key={key}
                className={`home-third-tab ${activeTab === key ? 'active' : ''}`}
                onClick={() => setActiveTab(key)}
              >
                <div className="home-third-tab-icon">{icon}</div>
                <span>{title}</span>
              </button>
            ))}
          </div>
          <div className="home-third-tab-content">
            <h3>{applications[activeTab].title}</h3>
            <ul>
              {applications[activeTab].items.map((item: string, index: number) => (
                <li key={index}>
                  <ChevronRight size={20} />
                  <span>{item}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="home-third-recycling">
          <div className="home-third-recycling-content">
            <h3>Die Zukunft des Recyclings</h3>
            <p>Was, wenn Styror Airpop seinen Zweck erfüllt hat? Bisland wurde es meistens verbrannt, also thermisch verwertet. Besonders, wenn es mit Flammschutzmitteln wie HBCD belastet oder verunreinigt ist.</p>
          <p>Heute wissen wir, Verbrennung ist in vielerlei Hinsicht nicht die beste Alternative.</p>
          <p>Und aus technischer Sicht gibt es nun ganz neue Möglichkeiten.</p>
          </div>
          <div className="home-third-recycling-icon">
            <Recycle size={80} />
          </div>
        </div>

        <div className="home-third-cta">
          <h2>StyroR  –  StyroporRecycling</h2>
          <p>Ihr Spezialist und Ansprechpartner für gewinnsteigerndes Styropor Airpop Recycling-Management</p>
          <div className="home-third-slogan">
            <p>Sie retten die Welt  &  machen Geld</p>
          </div>
          <Link to="/kontakt" className="home-third-button">
            <Mail size={20} />
            <span>Kontakt aufnehmen</span>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default HomeThird;