// Datenschutz.tsx
import React from 'react';
import './Datenschutz.css';

const Datenschutz: React.FC = () => {
  return (
    <main className="datenschutz">
      <div className="datenschutz__container">
        <h1 className="datenschutz__title">Datenschutzerklärung</h1>

        <section className="datenschutz__section">
          <h2>Datenschutz</h2>
          <p>
            Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre
            personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie
            dieser Datenschutzerklärung.
          </p>
          <p>
            Die Nutzung unserer Website ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf
            unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder E-Mail-Adressen) erhoben
            werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre
            ausdrückliche Zustimmung nicht an Dritte weitergegeben.
          </p>
          <p>
            Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei der Kommunikation per E-Mail)
            Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
            möglich.
          </p>
        </section>

        <section className="datenschutz__section">
          <h2>Cookies</h2>
          <p>
            Die Internetseiten verwenden teilweise sogenannte Cookies. Cookies richten auf Ihrem Rechner keinen
            Schaden an und enthalten keine Viren. Cookies dienen dazu, unser Angebot effektiver, nutzerfreundlicher
            und sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr
            Browser speichert.
          </p>
          <p>
            Die meisten der von uns verwendeten Cookies sind sogenannte „Session-Cookies". Sie werden nach Ende
            Ihres Besuchs automatisch gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese
            löschen. Diese Cookies ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen.
          </p>
          <p>
            Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und
            Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell
            ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browser aktivieren. Bei der
            Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.
          </p>
        </section>

        <section className="datenschutz__section">
          <h2>Server-Log-Files</h2>
          <p>
            Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log Files,
            die Ihr Browser automatisch an uns übermittelt. Dies sind:
          </p>
          <ul>
            <li>Browsertyp/ Browserversion</li>
            <li>verwendetes Betriebssystem</li>
            <li>Referrer URL</li>
            <li>Hostname des zugreifenden Rechners</li>
            <li>Uhrzeit der Serveranfrage</li>
          </ul>
          <p>
            Diese Daten sind nicht bestimmten Personen zuordenbar. Eine Zusammenführung dieser Daten mit anderen
            Datenquellen wird nicht vorgenommen. Wir behalten uns vor, diese Daten nachträglich zu prüfen, wenn uns
            konkrete Anhaltspunkte für eine rechtswidrige Nutzung bekannt werden.
          </p>
        </section>

        <section className="datenschutz__section">
          <h2>Einbindung von Diensten und Inhalten Dritter</h2>
          <p>
            Es kann vorkommen, dass innerhalb dieses Onlineangebotes Inhalte Dritter, wie zum Beispiel Videos von
            YouTube, Kartenmaterial von Google-Maps, RSS-Feeds oder Grafiken von anderen Websites eingebunden
            werden. Dies setzt immer voraus, dass die Anbieter dieser Inhalte (nachfolgend bezeichnet als
            „Drittanbieter") die IP-Adresse der Nutzer wahrnehmen. Denn ohne die IP-Adresse könnten sie die Inhalte
            nicht an den Browser des jeweiligen Nutzers senden. Die IP-Adresse ist damit für die Darstellung dieser
            Inhalte erforderlich. Wir bemühen uns nur solche Inhalte zu verwenden, deren jeweilige Anbieter die IP-
            Adresse lediglich zur Auslieferung der Inhalte verwenden. Jedoch haben wir keinen Einfluss darauf, ob die
            Drittanbieter die IP-Adresse z. B. für statistische Zwecke speichern. Soweit uns dies bekannt wird, klären wir
            die Nutzer darüber auf.
          </p>
        </section>

        <section className="datenschutz__section">
          <h2>Widerspruch zu Werbe-Mails</h2>
          <p>
            Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von
            nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die
            Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von
            Werbeinformationen, etwa durch Spam-E-Mails, vor.
          </p>
        </section>

        <section className="datenschutz__section">
          <h2>Kontaktformular</h2>
          <p>
            Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem
            Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage
            und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre
            Einwilligung weiter.
          </p>
        </section>

        <section className="datenschutz__section">
          <h2>Datenschutzbeauftragter</h2>
          <p>
            Nitsche Legal Herr Ra Dr. Thomas M. Nitsche<br />
            Brooktorkai 20, 20457 Hamburg<br />
            E-Mail: datenschutz@nitschelegal.de
          </p>
        </section>

        <section className="datenschutz__section">
          <h2>Website-Analyse mit Google Analytics</h2>
          <p>
            Diese Website nutzt Google Analytics, einen Webanalyse-Dienst der Google LLC, nachdem die Einwilligung
            des Benutzers eingeholt wurde. Die zuständigen Stellen sind Google Ireland Limited, Google Building
            Gordon House, 4 Barrow St, Dublin, D04 E5W5, Irland, für Benutzer aus der EU/EWR und der Schweiz.
          </p>
          <p>
            Google Analytics praktiziert standardmäßig die IP-Anonymisierung, d.h., Ihre IP-Adresse wird sofort
            gekürzt, wenn Sie mit dieser Website interagieren. Nur in seltenen Fällen wird Ihre vollständige IP an einen
            in den USA basierten Google-Server übertragen und dort gekürzt. Google beteuert, dass die über Google
            Analytics übertragene IP niemals mit anderen Google-Daten kombiniert wird. Google Analytics wird nur mit
            Ihrer Zustimmung über das Cookie-Banner aktiviert und kann jederzeit über die Cookie-Einstellungen
            rückgängig gemacht werden.
          </p>
          <p>
            Die verarbeiteten Daten umfassen: Nutzungsdaten wie Seitenaufrufe, Website-Interaktion, allgemeiner
            Standort und Besuchsdauer; und Kommunikationsdaten wie gekürzte IP-Adressen, User-Agent,
            Spracheinstellungen und Bildschirmauflösung. Der Zweck dieser Datenverarbeitung besteht darin, dass
            Google in Ihrem Namen die Nutzung Ihrer Website auswertet, Berichte über die Aktivitäten zusammenstellt
            und die Leistung der Website analysiert. Diese Datenverarbeitung ist legal, basierend auf deiner Zustimmung
            gemäß Art. 6 Abs. 1 S. 1 lit. a DSGVO und § 25 Abs. 1 S. 1 TDDDG.
          </p>
          <p>
            Google Ireland Limited, Google LLC und Alphabet Inc sind die Empfänger dieser Daten. Ihre Website-
            Nutzungsinformationen werden in der Regel an einen Google-Server in den USA gesendet, eine Aktion, die
            durch den jüngsten Angemessenheitsbeschluss der Europäischen Kommission vom 10. Juli 2023 legitimiert
            wurde, der die Zertifizierung von Google LLC unter dem EU-US-Datenschutzschild anerkennt. In Bezug auf
            die Datenspeicherung wird jede Daten, die mit Cookies verknüpft sind, automatisch nach einer bestimmten
            Zeit gelöscht, monatlich für Daten, die ihre Aufbewahrungsfrist erreicht haben, und maximal 2 Jahre für
            Google Analytics-Cookies.
          </p>
          <p>
            Für weitere Informationen zu den Nutzungsbedingungen von Google Analytics und zur Datenschutzrichtlinie
            von Google, besuchen Sie bitte https://policies.google.com/ und https://support.google.com/analytics/answer/12017362.
          </p>
          <p>
            Google Analytics verwendet sog. "Cookies", Textdateien, die auf dem Computer des Seitenbesuchers
            gespeichert werden und die eine Analyse der Benutzung der Website durch den Seitenbesucher ermöglichen.
            Die durch das Cookie erzeugten Informationen über die Benutzung dieser Website durch den Seitenbesucher
            (einschließlich der gekürzten IP-Adresse) werden in der Regel an einen Server von Google übertragen und
            dort gespeichert.
          </p>
          <p>
            Google Analytics wird ausschließlich mit der Erweiterung "_anonymizeIp()" auf dieser Website verwendet.
            Diese Erweiterung stellt eine Anonymisierung der IP-Adresse durch Kürzung sicher und schließt eine direkte
            Personenbeziehbarkeit aus. Durch die Erweiterung wird die IP-Adresse von Google innerhalb von
            Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den
            Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen
            Server von Google in den USA übertragen und dort gekürzt. Die im Rahmen von Google Analytics von dem
            entsprechenden Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google
            zusammengeführt.
          </p>
          <p>
            Im Auftrag des Seitenbetreibers wird Google die anfallenden Informationen benutzen, um die Nutzung der
            Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der
            Websitenutzung und der Internetnutzung verbundene Dienstleistungen dem Seitenbetreiber gegenüber zu
            erbringen (Art. 6 Abs. 1 lit. f DSGVO). Das berechtigte Interesse an der Datenverarbeitung liegt in der
            Optimierung dieser Website, der Analyse der Benutzung der Website und der Anpassung der Inhalte. Die
            Interessen der Nutzer werden durch die Pseudonymisierung hinreichend gewahrt.
          </p>
          <p>
            Google LLC. ist nach dem sog. Privacy Shield zertifiziert (Listeneintrag hier) und gewährleistet auf dieser
            Basis ein angemessenes Datenschutzniveau. Die gesendeten und mit Cookies, Nutzerkennungen (z. B. User-
            ID) oder Werbe-IDs verknüpften Daten werden nach 50 Monaten automatisch gelöscht. Die Löschung von
            Daten, deren Aufbewahrungsdauer erreicht ist, erfolgt automatisch einmal im Monat.
          </p>
          <p>
            Die Erfassung durch Google Analytics kann verhindert werden, indem der Seitenbesucher die Cookie-
            Einstellungen für diese Website anpasst. Der Erfassung und Speicherung der IP-Adresse und der durch
            Cookies erzeugten Daten kann außerdem jederzeit mit Wirkung für die Zukunft widersprochen werden. Das
            entsprechende Browser- Plugin kann unter dem folgenden Link heruntergeladen und installiert werden: https://tools.google.com/dlpage/gaoptout.
          </p>
          <p>
            Der Seitenbesucher kann die Erfassung durch Google Analytics auf dieser Webseite verhindern, indem er auf
            folgenden Link klickt. Es wird ein Opt-Out-Cookie gesetzt, der die zukünftige Erfassung der Daten beim
            Besuch dieser Website verhindert.
          </p>
          <p>
            Weitere Informationen zur Datennutzung durch Google, Einstellungs- und Widerspruchsmöglichkeiten,
            finden sich in der Datenschutzerklärung von Google https://policies.google.com/privacy sowie in den
            Einstellungen für die Darstellung von Werbeeinblendungen durch Google https://adssettings.google.com/authenticated.
          </p>
        </section>

        <section className="datenschutz__section">
          <h2>Spamschutz mit Google reCAPTCHA</h2>
          <p>
            Wir haben ggf. Google reCAPTCHA in Formularen auf unserer Webseite implementiert, um zu überprüfen,
            ob die in den Formularen eingegebenen Daten von menschlichen Besuchern stammen oder von Maschinen
            oder automatisierten Programmen, die auch als "Bots" bekannt sind. Dieses Tool analysiert automatisch das
            Verhalten der Webseitenbesucher, sobald sie mit der Webseite interagieren, unter Verwendung verschiedener
            Informationen wie IP-Adresse, Aufenthaltsdauer und Mausbewegungen. Die verarbeiteten Daten umfassen
            Nutzungsdaten wie die aufgerufene Webseite, Datum und Uhrzeit des Zugriffs und Mausbewegungen sowie
            Kommunikationsdaten wie IP-Adressen, Browsertyp und Betriebssystem.
          </p>
          <p>
            Diese Verarbeitung richtet sich an Webseitenbesucher und Nutzer von Online-Diensten. Der Hauptzweck dieser Verarbeitung besteht darin, den
            Missbrauch unseres Kontaktformulars zu verhindern und dadurch die Sicherheit zu erhöhen. Die rechtliche
            Grundlage dafür ist unser berechtigtes Interesse gemäß Art. 6 Abs. 1 S. 1 lit. f. DSGVO. Die
            Datenempfänger sind Google Ireland Ltd, ansässig in Google Building Gordon House, Barrow St, Dublin 4,
            Irland, https://policies.google.com/privacy und unser Webseiten-Hosting-Anbieter. Während Ihre
            persönlichen Daten innerhalb der EU verarbeitet werden, wird die Aufbewahrungsfrist für die verarbeiteten
            Daten von Google Ireland Limited bestimmt. Weitere Informationen finden Sie in der Datenschutzerklärung
            von Google reCAPTCHA: https://policies.google.com/privacy
          </p>
        </section>

        <section className="datenschutz__section">
          <h2>Webseitenanalyse</h2>
          <p>
            Wenn Sie unsere Website besuchen, sammeln wir Informationen über Ihre Nutzung durch ein Webanalyse-
            Tool, das von unserem Hosting-Service bereitgestellt wird. Dieses Tool sammelt und kombiniert Ihre IP-
            Adresse und User Agent, verkürzt sie und speichert diese Daten mit einer Hash-Funktion. Durch diesen
            Vorgang wird eine Besucherkennung erstellt, die mit einem zufällig generierten Wert (SALT) verschlüsselt
            ist, der sich alle 24 Stunden ändert. Diese Methode stellt sicher, dass Ihre IP-Adresse nicht aus dem
            gespeicherten Besucheridentifikator rekonstruiert werden kann, so dass Ihre Anonymität gewahrt bleibt.
            Außerdem führen wir diese Informationen nicht mit anderen Daten zusammen und sie werden nur auf dem
            Server des Hosting-Anbieters gespeichert.
          </p>
          <p>
            Wir verarbeiten auch Webanalysedaten, HTTP-Daten und Webanalyseprofildaten. Das von uns verwendete
            Webanalysetool erstellt und speichert ein Webanalyseprofil, das Details über Ihre Nutzung der Website
            enthält, wie z. B. Seitenaufrufe, Besuchshäufigkeit, Verweildauer auf jeder Seite und den User Agent Ihres
            Geräts. Dazu gehören Nutzungsdaten (wie besuchte Webseiten und Zugriffszeiten) und
            Kommunikationsdaten (wie Browsertyp, Betriebssystem und IP-Adressen).
          </p>
          <p>
            Mit der Verarbeitung dieser Daten wollen wir das Nutzerverhalten in zusammengefasster Form analysieren,
            um die Darstellung und den Inhalt unserer Website zu verbessern. Die Rechtsgrundlage für diese
            Verarbeitung ist unser berechtigtes Interesse (Art. 6 Abs. 1 lit. (f) GDPR), insbesondere an der Durchführung
            von Webmessungen, um unsere Produkte und unsere Website zu verbessern.
          </p>
          <p>
            Die erhobenen Daten werden mit unserem Website-Hosting-Anbieter geteilt und innerhalb der EU
            verarbeitet.
          </p>
        </section>
      </div>
    </main>
  );
};

export default Datenschutz;