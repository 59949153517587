import React from 'react';
import heroImage from '../../assets/hero.webp';
import './HomeHero.css';

const HomeHero: React.FC = () => {
  return (
    <section className="home-hero">
      <div className="home-hero__image-container">
        <img src={heroImage} alt="Styropor Recycling Prozess" className="home-hero__image" />
      </div>
      <div className="home-hero__overlay"></div>
      <div className="home-hero__content">
        <h1 className="home-hero__title">
          <span className="home-hero__title-main">StyroR</span>
          <span className="home-hero__title-sub">Styropor Recycling</span>
        </h1>
        <p className="home-hero__description">Ihr Spezialist und Ansprechpartner für gewinnbringendes Recycling & Management von Styropor Airpop</p>
        <p className="home-hero__tagline">Sie retten die Welt & verdienen Geld</p>
      </div>
    </section>
  );
};

export default HomeHero;