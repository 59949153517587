import React from 'react';
import './HomeFirst.css'
import Title from '../../components/Title/Title';

const HomeFirst: React.FC = () => {
  return (
    <section className="home-first-container">
      <div className="home-first-content">
        <p className="home-first-preHeader">
          Steigern Sie Ihren Gewinn + Reduzieren Sie Ihre Kosten 🡢	Lukratives Recycling von
        </p>
        <Title
          animated="Transportverpackung Isolierung Wärmedämmung"
        />
        <p className="home-first-subtitle">
          Auch verunreinigt, verdreckt, belastet oder mit Flammschutzmittel HBCD kontaminiert!
        </p>
        <div className="home-first-grid">
          <div className="home-first-card">
            <h2>EPS & Styropor</h2>
            <ul>
              <li>Expandiertes Styropor</li>
              <li>Expandiertes Polystyrol</li>
              <li>"airpop®"</li>
              <li>Belastetes Styropor (HBCD)</li>
            </ul>
          </div>
          <div className="home-first-card">
            <h2>Weitere Materialien</h2>
            <ul>
              <li>Extrudierter Polystyrol-Hartschaum (XPS)</li>
              <li>Expandiertes Polypropylen (EPP)</li>
              <li>Expandiertes Polyethylen (EPE)</li>
              <li>Polyurethan (PUR)</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeFirst;