import React from 'react';
import './Contact.css';
import { FaPhone, FaEnvelope, FaGlobe, FaWhatsapp, FaMapMarkerAlt } from 'react-icons/fa';
import backgroundImage from '../../assets/contact.webp';

const Contact: React.FC = () => {
  const openWhatsApp = () => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const phoneNumber = '4915903782712';
    const url = isMobile
      ? `whatsapp://send?phone=${phoneNumber}`
      : `https://web.whatsapp.com/send?phone=${phoneNumber}`;
    window.open(url, '_blank');
  };

  return (
    <div className="contact-container">
      <div className="contact-background" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="background-overlay"></div>
      </div>
      <div className="contact-content">
        <div className="contact-info">
          <h1 className="contact-title">Kontakt</h1>
          <div className="company-info">
            <h2>StyroR</h2>
            <p>Styropor Recycling Management und Beratung UG (haftungsbeschränkt) i.G.</p>
          </div>
          <div className="contact-details">
            <div className="contact-item">
              <FaMapMarkerAlt className="icon" />
              <div>
              <p>Hamburg West: Am Barls 241, 22549 Hamburg</p>
              <p>Hamburg Ost: Am Pfeilshof 55, 22393 Hamburg</p>
              </div>
            </div>
            <div className="contact-item">
              <FaPhone className="icon" />
              <a href="tel:+4915903782712">+49 (0)1590 – 37 82 712</a>
            </div>
            <div className="contact-item">
              <FaEnvelope className="icon" />
              <a href="mailto:info@styror.com">info@styror.com</a>
            </div>
            <div className="contact-item">
              <FaGlobe className="icon" />
              <a href="https://www.styror.com" target="_blank" rel="noopener noreferrer">www.styror.com</a>
            </div>
          </div>
          <button onClick={openWhatsApp} className="whatsapp-button">
            <FaWhatsapp /> WhatsApp Chat starten
          </button>
        </div>
      </div>
    </div>
  );
};

export default Contact;