import React, { useState } from 'react';
import './BusinessModelFirst.css';
import Title from '../../components/Title/Title';

import image1 from '../../assets/20.jpg';
import image2 from '../../assets/23.jpg';
import image4 from '../../assets/25.jpg';
import image5 from '../../assets/31.jpg';
import image6 from '../../assets/32.jpg';
import image7 from '../../assets/37.jpg';
import image8 from '../../assets/41.jpg';
import image9 from '../../assets/4.jpg';
import image10 from '../../assets/5.jpg';
import image11 from '../../assets/40.jpg';

interface ModalProps {
  src: string;
  alt: string;
  onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({ src, alt, onClose }) => (
  <div className="business-model-first-modal" onClick={onClose}>
    <img src={src} alt={alt} className="business-model-first-modal-image" onClick={(e) => e.stopPropagation()} />
  </div>
);

const BusinessModelFirst: React.FC = () => {
  const [modalImage, setModalImage] = useState<string | null>(null);

  const openModal = (src: string) => setModalImage(src);
  const closeModal = () => setModalImage(null);

  return (
    <div className="business-model-first">
      <div className="business-model-first-content">
        <div className="business-model-first-title">
          <Title 
            animated="Styropor-Airpop"
            static="Anwendungen und Lebenszyklus"
          />
        </div>
        <div className="business-model-first-process">
          <div className="business-model-first-main-applications">
            <section className="business-model-first-section">
              <h2 className="business-model-first-subtitle">Wärmedämmung</h2>
              <p className="business-model-first-text">
                Styropor-Airpop findet Anwendung in der Wärmedämmung an und in Gebäuden
              </p>
              <div className="business-model-first-image-container business-model-first-image-container-1">
                <img src={image1} alt="Wärmedämmung 1" className="business-model-first-image" onClick={() => openModal(image1)} />
                <img src={image2} alt="Wärmedämmung 2" className="business-model-first-image" onClick={() => openModal(image2)} />
              </div>
            </section>
            <section className="business-model-first-section">
              <h2 className="business-model-first-subtitle">Transportverpackung</h2>
              <p className="business-model-first-text">
                Als Transportverpackung für unzählige Artikel des täglichen modernen Lebens
              </p>
              <div className="business-model-first-image-container business-model-first-image-container-1">
                <img src={image4} alt="Transportverpackung 1" className="business-model-first-image" onClick={() => openModal(image4)} />
                <img src={image5} alt="Transportverpackung 2" className="business-model-first-image" onClick={() => openModal(image5)} />
              </div>
            </section>
            <section className="business-model-first-section">
              <h2 className="business-model-first-subtitle">Branchenspezifische Anwendungen</h2>
              <p className="business-model-first-text">
                Vielfältige Einsatzmöglichkeiten in verschiedenen Branchen
              </p>
              <div className="business-model-first-image-container business-model-first-image-container-1">
              <img src={image6} alt="Branchenspezifische Anwendung 1" className="business-model-first-image" onClick={() => openModal(image6)} />
                <img src={image8} alt="Branchenspezifische Anwendung 2" className="business-model-first-image" onClick={() => openModal(image8)} />
              </div>
            </section>
          </div>
          <div className="business-model-first-arrow">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <line x1="12" y1="5" x2="12" y2="19"></line>
              <polyline points="19 12 12 19 5 12"></polyline>
            </svg>
          </div>
          <div className="business-model-first-lifecycle">
            <section className="business-model-first-section">
              <h2 className="business-model-first-subtitle">Nach Gebrauch</h2>
              <p className="business-model-first-text">
                Was passiert mit diesem wertvollen Rohstoff nach der Nutzung? Viel zu oft schaut es mit Styropor so aus: Abfall
              </p>
              <div className="business-model-first-image-container business-model-first-image-container-16-9">
                <img src={image9} alt="Nach Gebrauch 1" className="business-model-first-image" onClick={() => openModal(image9)} />
                <img src={image10} alt="Nach Gebrauch 2" className="business-model-first-image" onClick={() => openModal(image10)} />
              </div>
            </section>
          </div>
        </div>
      </div>
      {modalImage && (
        <Modal src={modalImage} alt="Expanded view" onClose={closeModal} />
      )}
    </div>
  );
};

export default BusinessModelFirst;