import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import duobyte from '../../assets/duobyte.png';
import logo from '../../assets/logo.png';
import './Footer.css';

const Footer: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-main">
          <img src={logo} alt="StyroR Logo" className="footer-logo" />
          <h2 className="footer-slogan">Styropor Recycling</h2>
        </div>
        <nav className="footer-nav">
          <Link to="/" className="footer-link">Überblick</Link>
          <Link to="/modell" className="footer-link">Geschäftsmodell</Link>
          <Link to="/kontakt" className="footer-link">Kontakt</Link>
          <Link to="/impressum" className="footer-link">Impressum</Link>
          <Link to="/datenschutz" className="footer-link">Datenschutz</Link>
        </nav>
      </div>
      <div className="footer-bottom">
        <p className="footer-copyright">© {new Date().getFullYear()} StyroR – Alle Rechte vorbehalten</p>
        <div className="footer-credit">
          <span>Diese Seite wurde erstellt von</span>
          <a href="https://duobyte.dev" target="_blank" rel="noopener noreferrer" className="footer-duobyte">
            <img src={duobyte} alt="DuoByte Logo" className="footer-duobyte-logo" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;